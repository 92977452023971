export const enMessages = {
  acervos: "Libraries",
  Acessar: "Login",
  alias: "Alias",
  ate: "to",
  bookmarks: "Bookmarks",
  capas: "Covers",
  cliqueParaPesquisar: "Click to search",
  compartilharNo: "Share on",
  copypageurl: "Copy page hyperlink",
  copysearchurl: "Copy search hyperlink",
  copiedurl: "Hyperlink copied",
  descricao: "Description",
  digiteTextoParaPesquisar: "Type a text to search...",
  doctypetitle: "Metadata",
  download: "Download",
  downloads: "Downloads",
  email: "Email",
  ErroDesconhecido: "Unknown error",
  exibir: "Show",
  exportdisablemsg: "Export disabled",
  exportpdf: "Export to PDF",
  FalhaLogin: "Failed to login",
  fechar: "Close",
  fecharMenu: "Close menu",
  filtrar: "Filter",
  filtrarNomesDeBibliotecas: "Filter library names...",
  gerar: "Generate",
  incluirSubPastas: "Include subfolders",
  incluirSubpastas: "Include subfolders",
  info: "Info",
  informacoes: "Informations",
  librarynotfound: "Library not found",
  limpaaPesquisa: "Clear search",
  LoginIncorreto: "Incorrect login or password",
  message: "Message",
  miniaturas: "Thumbnails",
  miniaturasCapas: "Thumbnails of covers",
  miniaturasMarcadas: "Marked thumbnails",
  miniaturasOcorrencias: "Thumbnails of occurrences",
  naoExistePaginas: "There are no pages to generate a PDF",
  name: "Name",
  ocorrencias: "Matchs",
  ocultar: "Hide",
  pagina: "Page",
  PaginaNaoencontrada: "Page not found (404)",
  paginas: "Pages",
  paginasDe: "Pages from",
  pasta: "Folder",
  pastas: "Folders",
  Pastas: "Folders",
  pesquisarNasBibliotecas: "Search in libraries...",
  pesquisar: "Search",
  phone: "Phone",
  pressioneEnterParaPesquisar: "Press ENTER to search",
  qualidade: "Quality",
  recoverypasswordinfo1: "Enter your email to send the security code.",
  recoverypasswordinfo2: "Enter the code received by email and enter the new password.",
  semOcorrencias: "No matchs",
  send: "Send",
  password: "Password",
  talktous: "Talk to us...",
  talktousmessage: "Leave your comments, questions or suggestions so we can know what your experience has been like using this application.",
  todaAPastaAtual: "All current folder",
  todasOcorrencias: "All occurrences",
  todasOcorrenciasPastaAtual: "Occurrences of the current folder",
  totalDePaginas: "Total pages",
  totalDePastas: "Total folders",
  Usuario: "User",
  zoom: "Zoom",
};

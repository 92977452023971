import React, { ReactElement, useEffect } from 'react';
import { libraryApi } from '../apis/library';
import { useLibraryContext } from '../context/LibraryContext';
import { useNavigate, useParams } from 'react-router-dom';
import { DocReader } from '../pages/docreader';
import ErrorMessage from '../components/ErrorMessage';
import { Loader } from '@progress/kendo-react-indicators';
import { DocMulti } from '../pages/docmulti';
import { useSearchTextContext } from '../context/SearchTextContext';
import { useLibrariesContext } from '../context/LibrariesContext';
import { useFoldersContext } from '../context/FoldersContext';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import { isMobile } from 'react-device-detect';
import { findFolderAndPageByPageId, findFolderById, findFolderByName, findFolderWithPage } from '../services/shared';
import { getDefaultBiblioteca } from '../services/appSettings';
import { useControlContext } from '../context/ControlContext';

type RouteParams = {
  library: string;
  searchText?: string;
  folder?: string;
  page?: string;
  folderid?: string;
  pageid?: string;
};

export const BaseDocPro = (): ReactElement | null => {
  const { library, searchText, folder, page, folderid, pageid } = useParams<RouteParams>();
  const { Library, setLibrary } = useLibraryContext();
  const [ loading ] = React.useState<boolean>(true);
  const { Libraries } = useLibrariesContext();
  const { Folders } = useFoldersContext();
  const { setSearchText } = useSearchTextContext();
  const [ error, setError ] = React.useState<boolean>(false);
  const { setFolder } = useFolderContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  const { commandControlContext } = useControlContext();
  const navigate = useNavigate();

  const showBody = () => {
    const appBody = document.getElementById("appBody");
    if (appBody) {
      appBody.style.display = "block";
    }
  };

  showBody();

  useEffect(() => {
    if (Library === null) {
      const loadingX = loading;
      if (loadingX) {       
        const fetchData = async () => {
          try {
            const result = await libraryApi(library ?? getDefaultBiblioteca(), commandControlContext?.token ?? '');
            setLibrary(result);
            document.title = (result?.description === "") ? result?.name + " - " : document.title = result?.description + " - ";
            document.title +=  " DocReader Web";  
          }
          catch (error) {
            if (error == "Error: 403") {
              //console.log("/login?redirectto=" + window.location.href);
              navigate("/login?redirectto=" + window.location.href.replace("?redirectto=" + window.location.href, ""));
              return;
            }

            setError(true);
          };
        };

        fetchData();
      }
    }
  }, [loading, Library]);
  
  useEffect(() => {
    // DocReader Se posiciona na pasta, pagina ou pesquisa
    if (Folders === null || Folders === undefined) return;

    // Se entrou com pesquisa na URL, faz a pesquisa
    if (searchText !== null && searchText?.trim() !== '' && searchText !== undefined) {
      setSearchText(searchText);
      return;
    }

    // Se entrou com um pageid(pagina fisica) acha a pasta e pagina
    if (pageid != null && !isNaN(+pageid)) {
      const fetchData = async () => {        
        const result = await findFolderAndPageByPageId(library ?? getDefaultBiblioteca(), parseInt(pageid), commandControlContext?.token ?? '');

        let foldertmp;
        let pagetmp;
        
        if (result) {
          foldertmp = findFolderById(Folders, result.folderID);
          pagetmp = (result.page === 0) ? 1 : result.page;
        }

        if (!foldertmp) {
          foldertmp = findFolderWithPage(Folders) ?? Folders[0];
        }
  
        window.history.replaceState(null, "", "/" + (library ?? getDefaultBiblioteca()) + "/pageid/" + pageid);

        setFolder(foldertmp); 
        setPage((pagetmp ?? 0));
      }

      fetchData();

      // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
      setTimeout(() => {
        setPageLoad(true);
      }, 500);

      return;
    }

    // Se entrou com o nome ou id da pasta e uma página na URL. Procura a pasta e pagina, se nao passou a pagina entra na primeira
    if (folder != null || folderid != null ) {
      // Acha por folderID ou Folder name     
      let foldertmp = (folderid != null) ? findFolderById(Folders, parseInt(folderid?.trim() || '')) : findFolderByName(Folders, folder?.trim() || '');
      let pagetmp = parseInt(page ?? "1");

      // Caso nao achou o folder pelos parametros usa a primeira pasta
      if (foldertmp !== null) {
        if (pagetmp < 0) pagetmp = 1
        if (pagetmp > foldertmp.Pages) pagetmp = foldertmp.Pages
      }
      else {
        // Acha a primeira pasta com páginas
        foldertmp = findFolderWithPage(Folders) ?? Folders[0];
      }

      window.history.replaceState(null, "", "/" + (library ?? getDefaultBiblioteca()) + ((folderid == null || folderid == '') ? "/folder/" + folder : "/folderid/" + folderid) + ((page) ? "/page/" + page : ""));

      setFolder(foldertmp); 
      setPage((foldertmp.Pages === 0 ? 0 : pagetmp));  

      // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
      setTimeout(() => {
        setPageLoad(true);
      }, 100);

      return;
    }

    let foldertmp = findFolderWithPage(Folders) ?? Folders[0];
    setFolder(foldertmp); 
    setPage((foldertmp.Pages > 0 ? 1 : 0));  

    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  }, [Folders]);

   useEffect(() => {
    // DocMulti entra fazendo pesquisa
    if (Libraries === null || Libraries === undefined) return;

     if (searchText !== null && searchText?.trim() !== '' && searchText !== undefined) {
       // Se entrou com pesquisa na URL
       setSearchText(searchText);
     }
   }, [Libraries]);

  return (
    <>
      {Library === null ? 
        <>
            {error ?
              <ErrorMessage />
            :
              <span className={isMobile ? 'loader-mobile' : 'loader'}>
                <Loader type='converging-spinner' />
              </span>
            }
        </>
    :
        <>
          {Library.type === 1 ?
            <DocMulti library={library ?? getDefaultBiblioteca()} />
          :
            <DocReader library={library ?? getDefaultBiblioteca()} />
          }
        </>
      }
    </>
  );
};

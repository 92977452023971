import React, { ReactElement, useState } from 'react';
import { TextBox } from '@progress/kendo-react-inputs';
import { passwordResetCodeApi, passwordChangeApi } from '../apis/loginApi';
import { Button } from '@progress/kendo-react-buttons';
import { showMessage } from '../languages/_showmessages';
import { Notification } from '@progress/kendo-react-notification';
import { SvgIcon } from '@progress/kendo-react-common';
import { eyeIcon, eyeSlashIcon, xIcon } from '@progress/kendo-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';

export const PasswordRecovery: React.FC = (): ReactElement => {
  const [user, setUser] = useState('');
  const [code, setCode] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showchangepassword, setshowchangepassword] = useState(false);
  const [message, setMessage] = useState('');
  const [textbox1Type, setTextbox1Type] = React.useState('password');
  const [textbox2Type, setTextbox2Type] = React.useState('password');
  const [iconName, setIconName] = React.useState(eyeIcon);
  const [captchaKey, setCaptchaKey ] = useState('');

  function onChangecaptcha(value: any) {
    setCaptchaKey(value);
    console.log(value);
  }

  const handleSendCode = async () => {
    try {
      const response = await passwordResetCodeApi(user);
      //const response = await passwordResetCodeApi(user, captchaKey);
      if (response.sucesso) {
         setshowchangepassword(true);
      } else {
        setMessage(response.mensagem);
      }
    } catch (error) {
      setMessage(showMessage('passwordresetcodeerror'));
    }
  };

  const handleUserChange = (ev: any) => {
    setUser(ev.target.value);
  };

  const handlePassword1Change = (ev: any) => {
    setPassword1(ev.target.value);
  };

  const handlePassword2Change = (ev: any) => {
    setPassword2(ev.target.value);
  };

  const handlePassword1ShowHideClick = () => {
    setTextbox1Type(textbox1Type === 'password' ? 'text' : 'password');
    setIconName(iconName === eyeIcon ? eyeSlashIcon : eyeIcon);
  };

  const handlePassword2ShowHideClick = () => {
    setTextbox2Type(textbox2Type === 'password' ? 'text' : 'password');
    setIconName(iconName === eyeIcon ? eyeSlashIcon : eyeIcon);
  };

  const handleCodeChange = (ev: any) => {
    setCode(ev.target.value);
  }

  const handleChangePassword = async () => {
    try {
      if (password1 !== password2) {
        setMessage(showMessage('passwordsnotmatch'));
        return;
      }

      const response = await passwordChangeApi(user, code, "", password1);
      if (response.sucesso) {
         setshowchangepassword(true);
      } else {
        setMessage(response.mensagem);
      }
    } catch (error) {
      setMessage(showMessage('passwordchangeerror'));
    }
  }

  return (
    <>
      <div className="forgot-container" >
        {!showchangepassword ?
          <>
            <br />
            <br />
            <div>{showMessage("recoverypasswordinfo1")}</div>
            <br />
            <div>
              <TextBox className='userinput' type='text' value={user} placeholder={showMessage("email")} onChange={handleUserChange} />
            </div>
            <br />
            <ReCAPTCHA sitekey='6LflbNYqAAAAAKUMCxnvNrxhzw3Oj4Mj97H80K2r' onChange={onChangecaptcha}/>
            <br />
            <div className='loginButtonDiv'>
              <Button className='button' onClick={handleSendCode}>{showMessage("send")}</Button>
            </div>
          </> 
        :
          <>
            <br />
            <div>{showMessage("recoverypasswordinfo2")}</div>
            <div>
              <TextBox className='codeinput' type='text' maxLength={6} value={code} placeholder={"_ _ _ _ _ _"} onChange={handleCodeChange} size={'large'} aria-autocomplete='none' autoComplete='new-password'/>
            </div>
            <div>
              <TextBox className='passinput' type={textbox1Type} value={password1} placeholder={showMessage("password")} onChange={handlePassword1Change} aria-autocomplete='none' autoComplete='new-password' 
                      suffix={() => (<Button svgIcon={iconName} onClick={handlePassword1ShowHideClick} fillMode="flat" />)}/>
            </div>
            <div>
              <TextBox className='passinput' type={textbox2Type} value={password2} placeholder={showMessage("SenhaConfima")} onChange={handlePassword2Change} aria-autocomplete='none' autoComplete='new-password' 
                      suffix={() => (<Button svgIcon={iconName} onClick={handlePassword2ShowHideClick} fillMode="flat" />)}/>
            </div>
            <div className='loginButtonDiv'>
              <Button className='button' onClick={handleChangePassword}>{showMessage("Change")}</Button>
            </div>
          </>
        }

        {message  && (
          <Notification type={{ style: 'error', icon: false }} closable={false} onClose={() => setMessage('')} >
            <div onClick={() => setMessage('')} className='right'>
              <span><SvgIcon className='closeSvgIcon' icon={xIcon} /></span>
            </div>
            <br />
            <br />
            <div className='loginnotification'>
              {message}
            </div>
          </Notification>
        )}
      </div>
    </>
  );
};

export const ptMessages = {
  acervos: "Acervos",
  Acessar: "Acessar",
  alias: "Alias",
  ate: "até",
  bookmarks: "Marcadores",
  capas: "Capas",
  cliqueParaPesquisar: "Clique para pesquisar",
  compartilharNo: "Compartilhar no",
  copypageurl: "Copia o hyperlink da página",
  copysearchurl: "Copia o hyperlink da pesquisa",
  copiedurl: "Hyperlink copiado",
  descricao: "Descrição",
  digiteTextoParaPesquisar: "Digite um texto para pesquisar...",
  doctypetitle: "Metadados",
  download: "Download",
  downloads: "Downloads",
  email: "Email",
  ErroDesconhecido: "Erro desconhecido",
  exibir: "Exibir",
  exportdisablemsg: "Exportação desativada",
  exportpdf: "Exportar em PDF",
  FalhaLogin: "Falha ao tentar logar",
  fechar: "Fechar",
  fecharMenu: "Fechar menu",
  filtrar: "Filtrar",
  filtrarNomesDeBibliotecas: "Filtrar nomes de bibliotecas...",
  gerar: "Gerar",
  incluirSubPastas: "Incluir subpastas",
  incluirSubpastas: "Incluir subpastas",
  info: "Info",
  informacoes: "Informações",
  librarynotfound: "Biblioteca não encontrada",
  limpaaPesquisa: "Limpa a pesquisa",
  LoginIncorreto: "Login ou senha incorretos",
  message: "Mensagem",
  miniaturas: "Miniaturas",
  miniaturasCapas: "Miniaturas das capas",
  miniaturasMarcadas: "Miniaturas marcadas",
  miniaturasOcorrencias: "Miniaturas das ocorrências",
  naoExistePaginas: "Não existem páginas para gerar um PDF",
  name: "Nome",
  ocorrencias: "Ocorrências",
  ocultar: "Ocultar",
  pagina: "Página",
  PaginaNaoencontrada: "Página não encontrada",
  paginas: "Páginas",	
  paginasDe: "Páginas de",
  pasta: "Pasta",
  pastas: "Pastas",
  Pastas: "Pastas",
  pesquisarNasBibliotecas: "Pesquisar nas bibliotecas...",
  pesquisar: "Pesquisar",
  phone: "Telefone",
  pressioneEnterParaPesquisar: "Pressione ENTER para pesquisar",
  qualidade: "Qualidade",
  recoverypasswordinfo1: "Informe o seu email para enviar o código de segurança.",
  recoverypasswordinfo2: "Insira abaixo o código recebido por email e informe a nova senha.",
  semOcorrencias: "Sem ocorrências",
  send: "Enviar",
  password: "Senha",
  talktous: "Fale conosco...",
  talktousmessage: "Deixe seus comentários, dúvidas ou sugestões para que possamos saber como tem sido sua experiencia no uso deste aplicativo.",
  todaAPastaAtual: "Toda a pasta atual",
  todasOcorrencias: "Todas as ocorrências",
  todasOcorrenciasPastaAtual: "Ocorrências da pasta atual",
  totalDePaginas: "Total de páginas",
  totalDePastas: "Total de pastas",
  Usuario: "Usuário",
  zoom: "Zoom",
};
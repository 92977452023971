import React, { useState, useEffect } from 'react';
import { filterIcon, xIcon } from '@progress/kendo-svg-icons'; 
import { SvgIcon } from '@progress/kendo-react-common'; 
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Input } from '@progress/kendo-react-inputs';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { showMessage } from '../languages/_showmessages';
import { usePageLoadContext } from '../context/PageLoadContext';
import { useBookmarksContext } from '../context/BookmarksContext';
import { BookmarksModel } from '../models/bookmarksModel';
import { isMobile } from 'react-device-detect';

interface BookmarksProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Bookmarks: React.FC<BookmarksProps> = ({ isVisible, setIsVisible }) => {
  const [ filterValue, setFilterValue ] = React.useState();
  const [ data, setData ] = useState<BookmarksModel[]>([]);
  const [ dataResult, setDataResult ] = useState<BookmarksModel[]>([]);
  const { Folder } = useFolderContext();
  const { Bookmarks } = useBookmarksContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  
  const onFilterChange = (ev: any) => {
    let value = ev.value; 
    setFilterValue(ev.value);
    let newData = data.filter((item) => {
      let match = false;      
        if (
          item.Name
            .toString()
            .toLocaleLowerCase()
            .indexOf(value.toLocaleLowerCase()) >= 0
        ) {
          match = true;
        }              
      return match;
    });

    setDataResult(newData);
  };

  const onRowClick = (e: any) => {
    const item : BookmarksModel = e.dataItem;
    if (item==null) return;
    
    setPage(item.Page);

    setTimeout(() => {
      setPageLoad(true);
    }, 100);
  };

  useEffect(() => {
    let bookmarksperfolder: BookmarksModel[] = [];    
    Bookmarks?.forEach((item) => {
      if (item.FolderID == Folder?.Id) {
        bookmarksperfolder.push({PageID: item.PageID, FolderID: item.FolderID, Page: item.Page, Name: item.Name});
      }
    });
  
    setDataResult(bookmarksperfolder);
    setData(bookmarksperfolder);
  }, [Folder, Bookmarks]);  
  
  return (
    <>
      {isMobile ?
        <div className={isVisible ? 'leftpanel-mobile' : 'hidden'}>    
          <div className='leftpanelHeader'>                  
            <div className='leftpanelTitle'>{showMessage("bookmarks")}</div>   
            <div onClick={()=> setIsVisible(false)} className='leftpanelClose'>
              <span><SvgIcon className='closeSvgIcon' icon={xIcon} /></span>  
            </div>       
            <div className='leftpanelFilter' >
              <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon}/> </span>
              <Input className='leftpanelFilterInput' value={filterValue} onChange={onFilterChange} placeholder={showMessage("filtrar") + '...'} /> 
            </div>
          </div>

          {(dataResult?.length > 0) ?
            <div className='bookmarkdiv'>
              <Grid className='bookmarkgrid' data={dataResult} sortable={true} size={'small'} onRowClick={onRowClick} >
                <Column field='Name' title={showMessage("name")} width='350px' />
                <Column className='textright' field='Page' title={showMessage("pagina")} width='70px' />
              </Grid>
            </div>
          : 
            <></>
          }
        </div> 
      : 
        <div className={isVisible ? 'leftpanel' : 'hidden'}>    
          <div className='leftpanelHeader'>                  
            <div className='leftpanelTitle'>{showMessage("bookmarks")}</div>   
            <div onClick={()=> setIsVisible(false)} className='leftpanelClose'>
              <span><SvgIcon className='closeSvgIcon' icon={xIcon} /></span>  
            </div>       
            <div className='leftpanelFilter' >
              <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon}/> </span>
              <Input className='leftpanelFilterInput' value={filterValue} onChange={onFilterChange} placeholder={showMessage("filtrar") + '...'} /> 
            </div>
          </div>

          {(dataResult?.length > 0) ?
            <div className='bookmarkdiv'>
              <Grid className='bookmarkgrid' data={dataResult} sortable={true} size={'small'} onRowClick={onRowClick} >
                <Column field='Name' title={showMessage("name")} width='350px' />
                <Column className='textright' field='Page' title={showMessage("pagina")} width='70px' />
              </Grid>
            </div>
          : 
            <></>
          }
        </div>
      }
    </>
  )
};

export default Bookmarks;
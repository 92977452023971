import { FoldersModel } from '../models/foldersModel';
import { FoldersListModel } from '../models/foldersListModel';
import { MatchsModel } from '../models/searchModel';
import { FolderAndPageModel } from '../models/folderAndPageModel';
import { getFolderAndPage } from '../apis/document';
import { BookmarksModel } from '../models/bookmarksModel';

export const pathBaseMount = () => {         
    return window.location.origin;
}

export const findFolderAndPageByPageId = async (library: string, pageId: number, token: string): Promise<FolderAndPageModel | null> => {
  const result = await getFolderAndPage(library, pageId, token);
  return result;
};

export const findFolderById = (folders: FoldersModel[] | null, id: number): FoldersModel | null => {
  if (folders === null || id === 0) return null;

  for (let i = 0; i < folders.length; i++) {
    if (folders[i].Id === id) return folders[i];

    if (folders[i].SubFolders) {
      const folder = findFolderById(folders[i].SubFolders, id);
      if (folder) return folder;
    }
  }

  return null;
};

export const findFolderByName = (folders: FoldersModel[] | null, name: string): FoldersModel | null => {
  console.log(name);
  
  if (folders === null || name?.trim() === '') return null;

  for (let i = 0; i < folders.length; i++) {
    if (folders[i].Name.localeCompare(name, undefined, { sensitivity: 'base' }) === 0) return folders[i];

    if (folders[i].SubFolders) {
      const folder = findFolderByName(folders[i].SubFolders, name);
      if (folder) return folder;
    }
  }

  return null;
};

export const findFolderWithPage = (folders: FoldersModel[] | null): FoldersModel | null => {
  if (folders === null) return null;

  for (let i = 0; i < folders.length; i++) {
    if (folders[i].Pages > 0) return folders[i];

    if (folders[i].SubFolders) {
      const folder = findFolderWithPage(folders[i].SubFolders);
      if (folder) return folder;
    }
  }

  return null;
};

export const findLastSubFolder = (subFolders: FoldersModel | null, id: number): number | null => {
  if (subFolders === null) return id;
  return id + SubPastasTotal(subFolders);
};

export const findMatch = (matchs: MatchsModel[], folder: number, page: number): number | null => {
  if (matchs === null || folder === 0 || page === 0) return null;

  for (let i=0; i < matchs.length; i++) {
    if (matchs[i].folderID === folder && matchs[i].page === page) {
        return i;
    }
  }

  return null;
}

export const findMatchsPositions = (matchs: MatchsModel[], folder: number, page: number): number[] | null => {
  if (matchs === null || folder === 0 || page === 0) return null;

  for (let match of matchs) {
      if (match.folderID === folder && match.page === page) {
          return match.position;
      }
  }

  return null;
}

export const findBookmarkInFolder = (folderid: number, bookmarks: BookmarksModel[] | null): boolean => {
  if (bookmarks === undefined || bookmarks === null) return false;
  if ((folderid ?? 0) <= 0) return false;

  for (let i = 0; i < bookmarks.length; i++) {
    if (bookmarks[i].FolderID === folderid) {
      return true; 
    };
  }

  return false;
};

export const mapFoldersModel = (folders: FoldersModel[], parentPath: string, dataLista: FoldersListModel[]) => {
  folders.forEach((item) => {
    item.Path = (parentPath === '' ? '' : parentPath + '/') + item.Name;
    dataLista.push({
      Id: item.Id,
      Folders: item,
      Counter: dataLista.length + 1,
      Name: item.Name,
      PagesTotal: item.Pages ?? 0,
      FoldersTotal: SubPastasTotal(item)
    });
    if (item.SubFolders && item.SubFolders.length > 0) {
      mapFoldersModel(item.SubFolders, item.Path, dataLista);
    }
  });
};

const SubPastasTotal = (item: FoldersModel) : number => {
  let total = 0;
  if (item.SubFolders) {
    total = item.SubFolders.length;
    item.SubFolders.forEach(subitem => {
      total += SubPastasTotal(subitem)
    });
  }
  return total;
}

export const getFirstPixelColor = (image: any) => {
  return new Promise<string>((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error('Unable to get canvas context');
      }
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      const [r, g, b, a] = context.getImageData(0, 0, 1, 1).data;
      resolve(`rgba(${r}, ${g}, ${b}, ${a / 255})`);
    } catch (error) {
      reject(error);
    }
  });
};
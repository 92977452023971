import React, { useEffect } from 'react';
import { useLibraryContext } from '../../context/LibraryContext';
import { isMobile } from 'react-device-detect';
import { showMessage } from '../../languages/_showmessages';
import { AppBar, AppBarSection } from '@progress/kendo-react-layout';

export const Footer = () => {
  const { Library } = useLibraryContext();
  const [ footerImgStyle, setFooterImgStyle ] = React.useState<React.CSSProperties>({});

  useEffect(() => {
    if (Library && Library?.headerImg?.contentType !== undefined && Library?.headerImg?.fileContents !== undefined) {
      setFooterImgStyle({
        backgroundImage: `url(data:${Library.headerImg.contentType};base64,${Library.headerImg.fileContents})`,
        backgroundRepeat: 'repeat'
      });
    }    
  }, [Library]);

  return (
    <>
      {isMobile ? 
        <>
          <AppBar position='bottom' positionMode='fixed' style={footerImgStyle}>
            <div style={{width: '100%'}}>
              <div className='left'>
                <div className='infoFooter'>
                  {showMessage("acervos")}:&nbsp;{Library?.listLibraries.length}
                </div>
              </div>

              <div className='right'>
                <div className='infoFooter'>
                  {showMessage("paginas")}:&nbsp;{Library?.listLibraries?.reduce((sum, libraryItem) => sum + libraryItem.pages, 0)?.toLocaleString('pt-BR')}
                </div>
              </div>
            </div>

            <div className='center'>
              <div className='docprologo-docmulti' onClick={() => window.open("https://www.docpro.com.br", '_blank')} />
            </div>
          </AppBar>
        </>
      :
      <>
      </>
      }
    </>
  );
};

Footer.displayName = 'Footer';
import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { TextArea, TextBox } from '@progress/kendo-react-inputs';
import { showMessage } from '../languages/_showmessages';
import { Button } from '@progress/kendo-react-buttons';
import ReCAPTCHA from 'react-google-recaptcha';
import { browserName } from 'react-device-detect';

interface TalkToUsControlsProps {
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const TalkToUs: React.FC<TalkToUsControlsProps> = ({ setIsVisible }) => { 
  const [ name, setName ] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ reCaptchaKey, setReCaptchaKey ] = useState('');
  const [ sendButtonDisable, setSendButtonDisable ] = useState(true);

  function onChangecaptcha(value: any) {
    if (value != null) {
      setReCaptchaKey(value);
      setSendButtonDisable(false);
    }
  }
  
  function sendEmail(e: any) {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    emailjs.send('service_x4zn9ey', 'template_1k6p61r', {name: name, phone: phone, email: email, url: window.location.href, message: message, browser: browserName, 'g-recaptcha-response': reCaptchaKey}, 'Lza8_XOvnirY709sq')
      .then((result) => {
        setIsVisible(false);
      }, (error) => {
        console.log(error.text);
      });
  }

  return (
    <>
      <div className="talktousdiv">
        <div>
          {showMessage("talktousmessage")}
        </div>
        <div>
          <TextBox className='nametextbox' type='text' value={name} placeholder={showMessage("name")} onChange={(ev: any) => setName(ev.target.value)} />
        </div>
        <div>
          <TextBox className='phonetextbox' type='tel' value={phone} placeholder={showMessage("phone")} onChange={(ev: any) => setPhone(ev.target.value)} />
        </div>
        <div>
          <TextBox className='emailtextbox' type='email' value={email} placeholder={showMessage("email")} onChange={(ev: any) => setEmail(ev.target.value)} />
        </div>
        <div>
          <TextArea className='messagetextbox' value={message} placeholder={showMessage("message")} onChange={(ev: any) => setMessage(ev.target.value)} />
        </div>

        <div className='recaptchadiv'>
          <ReCAPTCHA className='center' sitekey='6LflbNYqAAAAAKUMCxnvNrxhzw3Oj4Mj97H80K2r' onChange={onChangecaptcha}/>
        </div>

        <div className='talktousbuttondiv'>
          <Button className='talktousbutton' onClick={sendEmail} disabled={sendButtonDisable}>{showMessage("send")}</Button>
        </div>
      </div>      
      </>
      )
}

export default TalkToUs;
import axios from 'axios';
import { BaseModel } from '../models/baseModel';
import { LibraryModel } from '../models/librariesModel';
import { getDocReaderAPI, getShowLogs } from '../services/appSettings';

const baseUrl = getDocReaderAPI();

export const libraryApi = async (library: string, token: string): Promise<LibraryModel | null> => {
  if (!baseUrl) {
    console.error('Environment variable REACT_APP_DOCREADERAPI is not set');
    throw new Error("DocReaderAPI Url not set");
  } 

  const url = `${baseUrl}/libraries/${library}`;
  const headers = token !== "" ? {headers: {Authorization: `Bearer ${token}`}} : {headers: {Authorization: `none`}};

  var result: Promise<LibraryModel> = axios.get(url.toString(), headers)
    .then(response => {
      var basedata = response.data as BaseModel;
      if (basedata.sucesso) {
        var data = basedata.data as LibraryModel;
        if (getShowLogs() === 'on') {console.log(data);}
        return data;
      }

      console.error(basedata.mensagem);
      throw new Error(basedata.mensagem);
    })
    .catch(error => {
      console.error(error);
      throw new Error(error.response.status);
    });
    return result;
};
import React, { ReactElement, useState } from 'react';
import { TextBox } from '@progress/kendo-react-inputs';
import { loginApi } from '../apis/loginApi';
import { Button } from '@progress/kendo-react-buttons';
import '../styles/Login.scss';
import { useNavigate, useSearchParams  } from 'react-router-dom';
import { showMessage } from '../languages/_showmessages';
import { useControlContext } from '../context/ControlContext';
import { Notification } from '@progress/kendo-react-notification';
import { SvgIcon } from '@progress/kendo-react-common';
import { eyeIcon, eyeSlashIcon, xIcon } from '@progress/kendo-svg-icons';

export const Login: React.FC = (): ReactElement => {
  const navigate = useNavigate();
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ message, setMessage ] = useState('');
  const { setCommandControlContext } = useControlContext();
  const [ searchParams ] = useSearchParams();
  const [ textboxType, setTextboxType ] = React.useState('password');
  const [ iconName, setIconName ] = React.useState(eyeIcon);
  
  const showBody = () => {
    const appBody = document.getElementById("appBody");
    if (appBody) {
      appBody.style.display = "block";
    }
  };

  showBody();

  const handleLogin = async () => {
    try {
      const response = await loginApi(username, password);
      if (response.sucesso) {
        setCommandControlContext({ token: response.token, userName: response.username });
        searchParams.get("redirectto") ? window.location.href = searchParams.get("redirectto") ?? "/" : navigate("/demo");
      } else {
        setMessage(response.mensagem);
      }
    } catch (error) {
      setMessage(showMessage('FalhaLogin'));
    }
  };

  const handleUsernameChange = (ev: any) => {
    setUsername(ev.target.value);
  };

  const handlePasswordChange = (ev: any) => {
    setPassword(ev.target.value);
  };
  
  const handlePasswordShowHideClick = () => {
    setTextboxType(textboxType === 'password' ? 'text' : 'password');
    setIconName(iconName === eyeIcon ? eyeSlashIcon : eyeIcon);
  };
  
  const PasswordRecovery = () => {
    navigate("/PasswordRecovery");
  }

  return (
    <>
      <div className="login-container">
        <div>
          <TextBox className='userinput' type='text' value={username} placeholder={showMessage("Usuario")} onChange={handleUsernameChange} />
        </div>
        <br></br>
        <div>
          <TextBox className='passinput' type={textboxType} value={password} placeholder={showMessage("password")} onChange={handlePasswordChange} 
                   suffix={() => (<Button svgIcon={iconName} onClick={handlePasswordShowHideClick} fillMode="flat" />)}/>
        </div>
        <div className='right'>
          <Button className='passwordforgotButton' fillMode="link" onClick={() => PasswordRecovery()} >
            Esqueceu a senha ?
          </Button>          
        </div>
        <br />
        <br />
        <br />
        <div className='loginButtonDiv'>
          <Button className='button' onClick={handleLogin}>{showMessage("Acessar")}</Button>
        </div>
        <br />
        <br />
        <br />

        {message  && (
          <Notification type={{ style: 'error', icon: false }} closable={false} onClose={() => setMessage('')} >
            <div onClick={() => setMessage('')} className='right'>
              <span><SvgIcon className='closeSvgIcon' icon={xIcon} /></span>
            </div>
            <br />
            <br />
            <div className='loginnotification'>
              {message}
            </div>
          </Notification>
        )}
      </div>
    </>
  );
};
